var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _vm.reMountedForm
        ? _c(
            "a-form-model",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "图标icon", prop: "icon" } },
                [
                  _c(
                    "div",
                    { staticClass: "check-icon-wrapper" },
                    _vm._l(_vm.iconList, function (item) {
                      return _c(
                        "span",
                        {
                          key: item.icon,
                          staticClass: "check-icon-item",
                          class: item.checked ? "icon-active" : "",
                          style: item.checked
                            ? "color:" + _vm.form.iconColor
                            : "",
                          on: {
                            click: function ($event) {
                              return _vm.checkIcon(item)
                            },
                          },
                        },
                        [
                          _c("i", {
                            class: [item.icon ? "iconfont " + item.icon : ""],
                          }),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "图标颜色", prop: "iconColor" } },
                [
                  _c("colorPicker", {
                    model: {
                      value: _vm.form.iconColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "iconColor", $$v)
                      },
                      expression: "form.iconColor",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "表单名称", prop: "approveName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入表单名称" },
                    model: {
                      value: _vm.form.approveName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "approveName", $$v)
                      },
                      expression: "form.approveName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "可发起部门", prop: "deptId" } },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "dropdown-style": {
                        maxHeight: "400px",
                        overflow: "auto",
                      },
                      "tree-data": _vm.deptOptions,
                      placeholder: "请选择",
                      replaceFields: _vm.replaceFields,
                      "tree-default-expand-all": "",
                    },
                    model: {
                      value: _vm.form.deptId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deptId", $$v)
                      },
                      expression: "form.deptId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "表单模板", prop: "template" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "请选择表单模板",
                        "allow-clear": "",
                      },
                      model: {
                        value: _vm.form.template,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "template", $$v)
                        },
                        expression: "form.template",
                      },
                    },
                    _vm._l(_vm.templateOptions, function (d, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: d.dictValue } },
                        [_vm._v(_vm._s(d.dictLabel))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "选择流程", prop: "procdefKey" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "请输入选择流程",
                        "allow-clear": "",
                      },
                      model: {
                        value: _vm.form.procdefKey,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "procdefKey", $$v)
                        },
                        expression: "form.procdefKey",
                      },
                    },
                    _vm._l(_vm.list, function (d, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: d.key } },
                        [_vm._v(_vm._s(d.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "表单说明", prop: "remark" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入表单说明" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vxe-modal",
                {
                  staticClass: "vxe-container",
                  attrs: {
                    width: "800px",
                    height: "600px",
                    title: "人员选择",
                    "show-zoom": true,
                    resize: "",
                    "lock-view": false,
                    "z-index": "2000",
                    mask: false,
                    position: "center",
                    transfer: "",
                  },
                  on: { close: _vm.handleCloseModal },
                  model: {
                    value: _vm.uaModalVisible,
                    callback: function ($$v) {
                      _vm.uaModalVisible = $$v
                    },
                    expression: "uaModalVisible",
                  },
                },
                [
                  _c("a-transfer", {
                    staticClass: "tree-transfer",
                    attrs: {
                      "data-source": _vm.employeeArray,
                      "target-keys": _vm.targetKeys,
                      render: function (item) {
                        return item.title
                      },
                      "show-search": "",
                      showSelectAll: false,
                    },
                    on: {
                      search: _vm.handleSearch,
                      change: _vm.handleChange,
                      selectChange: _vm.handleSelectChange,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "children",
                          fn: function (ref) {
                            var direction = ref.props.direction
                            var ref_on = ref.on
                            var itemSelect = ref_on.itemSelect
                            var itemSelectAll = ref_on.itemSelectAll
                            return [
                              direction === "left"
                                ? _c("a-tree", {
                                    attrs: {
                                      blockNode: "",
                                      checkable: "",
                                      "expanded-keys": _vm.expandedKeys,
                                      "auto-expand-parent":
                                        _vm.autoExpandParent,
                                      checkedKeys: _vm.selectedKeys.concat(
                                        _vm.targetKeys
                                      ),
                                      treeData: _vm.deptOptions,
                                      selectable: false,
                                    },
                                    on: {
                                      expand: _vm.treeExpand,
                                      check: function (_, props) {
                                        _vm.onChecked(
                                          _,
                                          props,
                                          _vm.selectedKeys.concat(
                                            _vm.targetKeys
                                          ),
                                          itemSelect,
                                          itemSelectAll
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "title",
                                          fn: function (ref) {
                                            var title = ref.title
                                            return [
                                              title.indexOf(_vm.searchValue) >
                                              -1
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          title.substr(
                                                            0,
                                                            title.indexOf(
                                                              _vm.searchValue
                                                            )
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "#f50",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.searchValue
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          title.substr(
                                                            title.indexOf(
                                                              _vm.searchValue
                                                            ) +
                                                              _vm.searchValue
                                                                .length
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(_vm._s(title)),
                                                  ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4036121930
                    ),
                  }),
                  _c(
                    "div",
                    { staticClass: "bottom-control" },
                    [
                      _c(
                        "a-space",
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.userModalConfirm },
                            },
                            [_vm._v(" 保存 ")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "dashed" },
                              on: { click: _vm.userModalcancel },
                            },
                            [_vm._v(" 取消 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "bottom-control" },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(" 保存 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "dashed" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v(" 取消 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }