<!-- eslint-disable vue/no-template-shadow -->
<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules" v-if="reMountedForm">
      <a-form-model-item label="图标icon" prop="icon">
        <div class="check-icon-wrapper">
          <span
            class="check-icon-item"
            :class="item.checked ? 'icon-active' : ''"
            v-for="item in iconList"
            :style="item.checked ? 'color:' + form.iconColor : ''"
            :key="item.icon"
            @click="checkIcon(item)"
          >
            <i :class="[item.icon ? 'iconfont ' + item.icon : '']"></i>
          </span>
        </div>
      </a-form-model-item>
      <a-form-model-item label="图标颜色" prop="iconColor">
        <colorPicker v-model="form.iconColor" />
      </a-form-model-item>
      <a-form-model-item label="表单名称" prop="approveName">
        <a-input v-model="form.approveName" placeholder="请输入表单名称" />
      </a-form-model-item>
      <a-form-model-item label="可发起部门" prop="deptId">
        <a-tree-select
          v-model="form.deptId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptOptions"
          placeholder="请选择"
          :replaceFields="replaceFields"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="表单模板" prop="template">
        <a-select placeholder="请选择表单模板" v-model="form.template" style="width: 100%" allow-clear>
          <a-select-option v-for="(d, index) in templateOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="选择流程" prop="procdefKey">
        <!-- <a-input v-model="form.procdefKey" placeholder="请输入选择流程" /> -->
        <a-select placeholder="请输入选择流程" v-model="form.procdefKey" style="width: 100%" allow-clear>
          <a-select-option v-for="(d, index) in list" :key="index" :value="d.key">{{ d.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item label="谁可以发起" prop="usersName">
        <a-select
          placeholder="请选择谁可以发起"
          v-model="usersName"
          @focus="userSelectFocus"
          :open="false"
          style="width: 100%"
        >
        </a-select>
      </a-form-model-item> -->
      <a-form-model-item label="表单说明" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入表单说明" />
      </a-form-model-item>
      <vxe-modal
        v-model="uaModalVisible"
        width="800px"
        height="600px"
        title="人员选择"
        :show-zoom="true"
        resize
        :lock-view="false"
        z-index="2000"
        :mask="false"
        position="center"
        @close="handleCloseModal"
        class="vxe-container"
        transfer
      >
        <!-- <a-transfer
      class="tree-transfer"
      :data-source="dataSource"
      :target-keys="targetKeys"
      :render="item => item.title"
      :show-select-all="false"
      @change="onChange" -->
        <!-- :filter-option="filterOption" -->
        <a-transfer
          :data-source="employeeArray"
          :target-keys="targetKeys"
          :render="(item) => item.title"
          show-search
          :showSelectAll="false"
          class="tree-transfer"
          @search="handleSearch"
          @change="handleChange"
          @selectChange="handleSelectChange"
        >
          <template
            slot="children"
            slot-scope="{ props: { direction }, on: { itemSelect, itemSelectAll } }"
          >
            <!-- <a-tree
              v-model="deptCheckedKeys"
              checkable
              :checkStrictly="!form.deptCheckStrictly"
              :expanded-keys="deptExpandedKeys"
              :auto-expand-parent="autoExpandParent"
              :tree-data="deptOptions"
              defaultExpandAll
              @expand="onExpandDept"
              :replaceFields="defaultProps"
              @check="onCheck"
            /> -->
            <a-tree
              v-if="direction === 'left'"
              blockNode
              checkable
              :expanded-keys="expandedKeys"
              :auto-expand-parent="autoExpandParent"
              :checkedKeys="[...selectedKeys, ...targetKeys]"
              :treeData="deptOptions"
              :selectable="false"
              @expand="treeExpand"
              @check="
                (_, props) => {
                  onChecked(_, props, [...selectedKeys, ...targetKeys], itemSelect, itemSelectAll)
                }
              "
            >
              <template slot="title" slot-scope="{ title }">
                <!-- 高亮搜索内容 -->
                <span v-if="title.indexOf(searchValue) > -1">
                  {{ title.substr(0, title.indexOf(searchValue)) }}
                  <span style="color: #f50">{{ searchValue }}</span>
                  {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
                </span>
                <span v-else>{{ title }}</span>
              </template>
            </a-tree>
          </template>
        </a-transfer>
        <div class="bottom-control">
          <a-space>
            <a-button type="primary" @click="userModalConfirm"> 保存 </a-button>
            <a-button type="dashed" @click="userModalcancel"> 取消 </a-button>
          </a-space>
        </div>
      </vxe-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm"> 保存 </a-button>
          <a-button type="dashed" @click="cancel"> 取消 </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getApprovalForm, addApprovalForm, updateApprovalForm } from '@/api/iot/approvalForm'
import { treeselect as deptTreeselect, roleDeptTreeselect } from '@/api/system/dept'
import { getRole } from '@/api/system/role'
import { list } from '@/api/approval/processDefinition'
import { Transfer, Tree, TreeSelect } from 'ant-design-vue'
import iconList from './iconList'
import { findList } from '@/api/system/user'

export default {
  name: 'CreateForm',
  props: {},
  components: {
    ATransfer: Transfer,
    ATreeSelect: TreeSelect,
    ATree: Tree
  },
  data() {
    return {
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      loading: false,
      reMountedForm: true,
      formTitle: '',
      // 表单参数
      form: {
        icon: null,
        approveName: null,
        procdefKey: undefined,
        remark: null,
        deptId: null,
        template: undefined,
        deptCheckStrictly: true,
        approvalTemplate: undefined,
        iconColor: '#000',
        users: undefined
      },
      usersName: undefined,
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        icon: [{ required: true, message: '图标icon不能为空', trigger: 'blur' }],
        approveName: [{ required: true, message: '表单名称不能为空', trigger: 'blur' }],
        template: [{ required: true, message: '表单模板不能为空', trigger: 'change' }],
        procdefKey: [{ required: true, message: '选择流程不能为空', trigger: 'blur' }],
        deptId: [{ required: true, message: '谁可以发起不能为空', trigger: 'change' }]
      },
      templateOptions: [],
      list: [],
      deptExpandedKeys: [],
      deptCheckedKeys: [],
      halfCheckedKeys: [],
      // deptOptions: [],
      // defaultProps: {
      //   children: 'children',
      //   title: 'label',
      //   key: 'id'
      // },
      iconList,
      employeeArray: [],
      targetKeys: [],
      selectedKeys: [],
      uaModalVisible: false,
      // 搜索值
      searchValue: '',
      expandedKeys: [],
      autoExpandParent: true,
      deptOptions: []
    }
  },
  filters: {},
  created() {
    this.getDictsOptions()
    this.getList()
    this.getDeptTreeselect()
  },
  computed: {},
  watch: {
    open(val) {
      if (val) {
        this.reMountedForm = false
        this.$nextTick(() => {
          this.reMountedForm = true
        })
      }
    }
  },
  mounted() {},
  methods: {
    async getDictsOptions() {
      await this.getDicts('iot_approval_template').then((response) => {
        this.templateOptions = response.data
      })
    },
    /** 查询流程列表 */
    async getList() {
      this.loading = true
      await list({}).then((response) => {
        if (response.rows.length > 0) {
          for (const item of response.rows) {
            if (JSON.stringify(this.list).indexOf(item.key) === -1) {
              this.list.push(item)
            }
          }
          this.$emit('getProcessList', this.list)
        }
        this.loading = false
      })
    },
    flatten(list = []) {
      // 穿梭框数据展现
      list.forEach((item) => {
        this.employeeArray.push(item)
        this.flatten(item.children)
      })
    },
    handleEmployeeSearch(value) {
      // 查询人员列表
      const queryParam = {}
      if (value) {
        queryParam.userName = value
      }
      this.getListUser(queryParam)
    },
    getListUser(queryParam = {}) {
      return new Promise((resolve, reject) => {
        this.loading = true
        findList().then((response) => {
          this.loading = false
          this.$emit('getUserArray', response.data)
          this.allUser = response.data
          resolve(response.data)
        })
      })
    },
    getUsersName(record) {
      // 解析对应用户id昵称
      let nameStr = ''
      const usersId = record.users.split(',')
      for (const j of usersId) {
        for (const item of this.allUser) {
          if (item.userId === Number(j)) {
            if (!nameStr) {
              nameStr += item.nickName
            } else {
              nameStr += ',' + item.nickName
            }
          }
        }
      }
      this.usersName = nameStr
    },
    onExpandDept(expandedKeys) {
      this.deptExpandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    /** 查询部门树结构 */
    getDeptTreeselect() {
      deptTreeselect().then(async (response) => {
        this.deptOptions = response.data
        // const users = await this.getListUser()
        // const deptUsers = this.classUsersBydeptId(users)
        // this.setDeptUsers(this.deptOptions, deptUsers)
        // this.allDeptOptions = JSON.parse(JSON.stringify(this.deptOptions))
        console.log('deptOptions', this.deptOptions)
        this.flatten(JSON.parse(JSON.stringify(this.deptOptions)))
      })
    },
    classUsersBydeptId(users) {
      const obj = {}
      for (const item of users) {
        if (!obj[item.deptId]) {
          obj[item.deptId] = [{ key: 'userId-' + item.userId + '-' + item.nickName, title: item.nickName }]
        } else {
          obj[item.deptId].push({ key: 'userId-' + item.userId + '-' + item.nickName, title: item.nickName })
        }
      }
      return obj
    },
    setDeptUsers(arr, users) {
      // 设置部门下人员
      for (const item of arr) {
        item.id && (item.key = item.id)
        item.label && (item.title = item.label)
        if (users.hasOwnProperty(item.id)) {
          // 如果人员列表中含有该部门，则设置部门人员
          if (item.children) {
            item.children = item.children.concat(users[item.id])
            this.setDeptUsers(item.children, users)
          } else {
            item.children = users[item.id]
          }
        } else if (item.children) {
          // 当前无部门人员但有下级部门则继续往下递归查找
          this.setDeptUsers(item.children, users)
        } else {
          // 最下级部门无人员
          if (typeof item.key === 'number') {
            // item.disabled = true
          }
        }
      }
    },
    // 所有部门节点数据
    getDeptAllCheckedKeys() {
      // 全选与半选
      return this.deptCheckedKeys.concat(this.halfCheckedKeys)
    },
    getAllDeptNode(nodes) {
      if (!nodes || nodes.length === 0) {
        return []
      }
      nodes.forEach((node) => {
        this.deptCheckedKeys.push(node.id)
        return this.getAllDeptNode(node.children)
      })
    },
    // 回显过滤
    selectNodefilter(nodes, parentIds) {
      if (!nodes || nodes.length === 0) {
        return []
      }
      nodes.forEach((node) => {
        // 父子关联模式且当前元素有父级
        const currentIndex = this.deptCheckedKeys.indexOf(node.id)
        // 当前节点存在,且父节点不存在，则说明父节点应是半选中状态
        if (currentIndex !== -1) {
          parentIds.forEach((parentId) => {
            if (this.halfCheckedKeys.indexOf(parentId) === -1) {
              this.halfCheckedKeys.push(parentId)
            }
          })
          parentIds = []
        }
        // 防重
        const isExist = this.halfCheckedKeys.indexOf(node.id)
        const isExistParentIds = parentIds.indexOf(node.id)
        if (isExist === -1 && isExistParentIds === -1 && currentIndex === -1) {
          parentIds.push(node.id)
        }
        return this.selectNodefilter(node.children, parentIds)
      })
    },
    handleCheckedTreeNodeAll(value) {
      if (value.target.checked) {
        this.getAllDeptNode(this.deptOptions)
      } else {
        this.deptCheckedKeys = []
        this.halfCheckedKeys = []
      }
    },
    handleCheckedTreeExpand(value) {
      if (value.target.checked) {
        const treeList = this.deptOptions
        for (let i = 0; i < treeList.length; i++) {
          this.deptExpandedKeys.push(treeList[i].id)
        }
      } else {
        this.deptExpandedKeys = []
      }
    },
    // 树权限（父子联动）
    handleCheckedTreeConnect(value) {
      this.form.deptCheckStrictly = !this.form.deptCheckStrictly
    },
    /** 根据角色ID查询部门树结构 */
    getRoleDeptTreeselect(roleId) {
      return roleDeptTreeselect(roleId).then((response) => {
        this.deptOptions = response.depts
        return response
      })
    },
    onCheck(checkedKeys, info) {
      if (!this.form.deptCheckStrictly) {
        let currentCheckedKeys = []
        if (this.deptCheckedKeys.checked) {
          currentCheckedKeys = currentCheckedKeys.concat(this.deptCheckedKeys.checked)
        }
        if (this.deptCheckedKeys.halfChecked) {
          currentCheckedKeys = currentCheckedKeys.concat(this.deptCheckedKeys.halfChecked)
        }
        this.deptCheckedKeys = currentCheckedKeys
      } else {
        // 半选节点
        this.halfCheckedKeys = info.halfCheckedKeys
        this.deptCheckedKeys = checkedKeys
      }
    },
    onChecked(_, e, checkedKeys, itemSelect, itemSelectAll) {
      // 穿梭框树选择回调
      const lastEventKey = JSON.parse(JSON.stringify(this.eventKey || []))
      this.userKeys = []
      this.eventKey = []
      console.log('onCHECKED', arguments)
      for (const item of arguments[0]) {
        this.eventKey.push(item)
        itemSelect(item, true)
        if (typeof item === 'string' && item.indexOf('userId-') > -1) {
          this.userKeys.push(item)
        }
      }
      for (const item of lastEventKey) {
        if (this.eventKey.indexOf(item) === -1) {
          itemSelect(item, false)
        }
      }
      // itemSelectAll(this.userKeys, !(checkedKeys.indexOf(eventKey) !== -1))
    },
    onSelect(_, e, checkedKeys, itemSelect) {
      // 穿梭框树选择回调
      const { eventKey } = e.node
      itemSelect(eventKey, !(checkedKeys.indexOf(eventKey) !== -1))
      // const userArr = []
      // for (const item of arguments[0]) {}
      console.log('onCHECKED', arguments)
      this.userKeys = []
      for (const item of arguments[0]) {
        if (typeof item === 'string' && item.indexOf('userId-') > -1) {
          this.userKeys.push(item)
        }
      }
    },
    treeExpand(expandedKeys, e, checkedKeys, itemSelect) {
      console.log('treeExpand', arguments)
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    checkIcon(item) {
      for (const iItem of this.iconList) {
        this.$set(iItem, 'checked', false)
      }
      this.$set(item, 'checked', true)
      this.form.icon = item.icon
    },
    onClose() {
      this.open = false
      this.userModalcancel()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      if (this.deptOptions.length === 0) {
        this.getTreeselect()
      }
      this.deptExpand = true
      this.deptNodeAll = false
      this.deptExpandedKeys = []
      this.autoExpandParent = false
      this.deptCheckedKeys = []
      this.halfCheckedKeys = []
      this.formType = 1
      this.form = {
        icon: null,
        approveName: null,
        procdefKey: undefined,
        users: undefined,
        remark: null,
        template: undefined,
        deptCheckStrictly: true,
        approvalTemplate: undefined,
        deptId: null,
        iconColor: '#000'
      }
      this.targetKeys = []
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getApprovalForm(id).then((response) => {
        this.form = response.data
        this.form.template = this.form.template + ''
        this.open = true
        this.formTitle = '修改'
        this.targetKeys = []
        this.getUsersName(response.data)
        const usersArr = response.data.users.split(',')
        const usersNameArr = this.usersName.split(',')
        for (let i = 0; i < usersArr.length; i++) {
          this.targetKeys.push('userId-' + usersArr[i] + '-' + usersNameArr[i])
        }
        console.log('this.targetKeys', this.targetKeys)
        this.handleTreeData(this.deptOptions, this.targetKeys)
        for (const iItem of this.iconList) {
          this.$set(iItem, 'checked', false)
          if (this.form.icon === iItem.icon) {
            this.$set(iItem, 'checked', true)
          }
        }
        // this.deptCheckedKeys = response.data.deptId.split(',').map(Number)
        // this.deptExpandedKeys = this.deptCheckedKeys
        // 过滤回显时的半选中node(父)
        // this.$set(this.form, 'deptCheckStrictly', true)
        // this.selectNodefilter(this.deptOptions, [])
        // console.log('this.deptCheckedKeys', this.deptCheckedKeys)
      })
    },
    /** 分配数据权限操作 */
    handleDataScope(row) {
      const roleDeptTreeselect = this.getRoleDeptTreeselect(row.roleId)
      getRole(row.roleId).then((response) => {
        this.form = response.data
        this.openDataScope = true
        this.$nextTick(() => {
          roleDeptTreeselect.then((res) => {
            this.deptCheckedKeys = res.checkedKeys
            // 过滤回显时的半选中node(父)
            if (this.form.deptCheckStrictly) {
              this.selectNodefilter(this.deptOptions, [])
            }
          })
        })
        this.formTitle = '分配数据权限'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      // this.form.deptId = this.deptCheckedKeys.join(',')
      this.$refs.form.validate((valid) => {
        if (valid) {
          delete this.form.users
          if (this.form.id !== undefined && this.form.id !== null) {
            updateApprovalForm(this.form).then((response) => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addApprovalForm(this.form).then((response) => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    },
    handleChange(targetKeys, direction, moveKeys) {
      console.log('targetKeys *****', targetKeys)
      if (this.userKeys && this.userKeys.length > 0) {
        this.targetKeys = this.userKeys
      } else {
        this.targetKeys = []
        for (const item of targetKeys) {
          if (typeof item === 'string' && item.indexOf('userId-') > -1) {
            this.targetKeys.push(item)
          }
        }
      }
      this.userKeys = []
      this.handleTreeData(this.deptOptions, this.targetKeys)
    },
    filterOption(inputValue, option) {
      return option.description.indexOf(inputValue) > -1
    },
    handleSelectChange() {},
    userSelectFocus() {
      console.log('userSelectFocus')
      this.uaModalVisible = true
    },
    handleTreeData(data, targetKeys = []) {
      data.forEach((item) => {
        item['disabled'] = targetKeys.includes(item.key)
        if (item.children) {
          this.handleTreeData(item.children, targetKeys)
        }
        this.$forceUpdate()
      })
      return data
    },
    userModalConfirm() {
      // 用户选择
      const users = []
      const usersName = []
      for (const item of this.targetKeys) {
        const arr = item.split('-')
        users.push(arr[1])
        usersName.push(arr[2])
      }
      this.form.users = users.join(',')
      this.usersName = usersName.join(',')
      this.userModalcancel()
    },
    userModalcancel() {
      this.uaModalVisible = false
    },
    getParentKey(key, tree) {
      let parentKey
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i]
        if (node.children) {
          if (node.children.some((item) => item.key === key)) {
            parentKey = node.key
          } else if (this.getParentKey(key, node.children)) {
            parentKey = this.getParentKey(key, node.children)
          }
        }
      }
      return parentKey
    },
    handleSearch(e) {
      // 人员选择穿梭框搜索
      console.log('人员搜索框 **', arguments)
      if (arguments[0] === 'left') {
        const value = arguments[1]
        const expandedKeys = value
          ? this.employeeArray
              .map((item) => {
                if (item.title.indexOf(value) > -1) {
                  return this.getParentKey(item.key, this.deptOptions)
                }
                return null
              })
              .filter((item, i, self) => item && self.indexOf(item) === i)
          : []
        Object.assign(this, {
          expandedKeys,
          searchValue: value,
          autoExpandParent: true
        })
      }
    },
    setCurrentTreeSource(arr) {
      if (arr) {
        this.deptOptions = JSON.parse(JSON.stringify(arr))
      } else {
        this.deptOptions = JSON.parse(JSON.stringify(this.allDeptOptions))
      }
    }
  }
}
</script>
<style scoped lang="less">
.check-icon-wrapper {
  .check-icon-item {
    display: inline-block;
    margin-right: 10px;
    width: 30px;
    box-sizing: border-box;
    text-align: center;
    &.icon-active {
      border: 1px dashed #888;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.4);
    }
    i {
      font-size: 24px;
    }
  }
}
.tree-transfer {
  max-height: 400px;
  .ant-transfer-list-body {
    overflow-y: scroll;
  }
  .ant-transfer-list:first-child {
    width: 50%;
    flex: none;
  }
}
</style>
<style lang="less">
.tree-transfer {
  max-height: 400px;
  .ant-transfer-list-body {
    overflow-y: scroll;
  }
}
</style>
